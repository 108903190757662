import React from "react";
import socketio from "socket.io-client";
import { SOCKET_ENDPOINT } from "../api/endpoint";

export const socket = token => {
  if (!token) return null;
  const socket = socketio(`${SOCKET_ENDPOINT}?token=${token}`, {
    transports: ["websocket", "polling"],
    upgrade: false
  });
  return socket;
};
export const SocketContext = React.createContext();
