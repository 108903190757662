import React from "react";

const DialogServiceContext = React.createContext(Promise.reject);

export const useDialog = () => React.useContext(DialogServiceContext);

export const DialogServiceProvider = ({ children }) => {
  const [DialogState, setDialogState] = React.useState(null);
  let Component;
  const awaitingPromiseRef = React.useRef();

  const openDialog = options => {
    setDialogState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (DialogState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setDialogState(null);
  };

  const handleSubmit = result => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(result);
    }

    setDialogState(null);
  };
  if (DialogState) {
    Component = DialogState.component;
  }
  return (
    <>
      <DialogServiceContext.Provider value={openDialog} children={children} />
      {Component ? (
        <Component
          open={Boolean(DialogState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...DialogState}
        />
      ) : null}
    </>
  );
};
