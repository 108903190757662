import { call, delay, put, takeLatest } from "redux-saga/effects";
import { GetWorkspaces, GetAllComponents } from "../../api/workspaceApi";
import {
  FETCH_SEARCH_WORKSPACE_COMPONENTS,
  FETCH_SEARCH_WORKSPACE_COMPONENTS_FAILED,
  FETCH_SEARCH_WORKSPACE_COMPONENTS_SUCCESS,
  FETCH_WORKSPACES,
  FETCH_WORKSPACES_FAILED,
  FETCH_WORKSPACES_SUCCESS
} from "../types/workspaces";

function* getWorkspaces() {
  try {
    const workspaces = yield call(async () => {
      return await GetWorkspaces();
    });
    yield put({
      type: FETCH_WORKSPACES_SUCCESS,
      workspaces: workspaces.data
    });
  } catch (e) {
    yield put({ type: FETCH_WORKSPACES_FAILED, message: e });
  }
}

function* fetchSearchWorkspacesComponents(action) {
  try {
    yield delay(200);
    const result = yield call(async () => {
      return await GetAllComponents(action.filter, action.limit);
    });
    yield put({
      type: FETCH_SEARCH_WORKSPACE_COMPONENTS_SUCCESS,
      searchWorkspaceComponents: result.data
    });
  } catch (e) {
    yield put({ type: FETCH_SEARCH_WORKSPACE_COMPONENTS_FAILED, message: e });
  }
}

function* workspaces() {
  yield takeLatest(FETCH_WORKSPACES, getWorkspaces);
  yield takeLatest(
    FETCH_SEARCH_WORKSPACE_COMPONENTS,
    fetchSearchWorkspacesComponents
  );
}

export default workspaces;
