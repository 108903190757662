export const isEligableToRun = snippet => {
  if (snippet.snippetTypeName == "kpi") {
    return { result: true };
  }
  if (snippet.snippetTypeName == "comment") {
    return { result: true };
  }
  if (snippet.snippetTypeName == "staticComment") {
    return { result: true };
  }
  if (snippet.snippetTypeName == "heatmap") {
    return { result: true };
  }
  if (snippet.snippetTypeName == "leaderboard") {
    return { result: true };
  }
  if (snippet.snippetTypeName == "dataTable") {
    return { result: true };
  }
  if (snippet.snippetTypeName == "overall") {
    return { result: true };
  }
  return false;
};
