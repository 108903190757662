export const PUBLISH_TEMPLATE = "PUBLISH_TEMPLATE";
export const PUBLISH_TEMPLATE_SUCCESS = "PUBLISH_TEMPLATE_SUCCESS";
export const PUBLISH_TEMPLATE_FAILED = "PUBLISH_TEMPLATE_FAILED";
export const SET_TEMPLATE = "SET_TEMPLATE";
export const SET_SELECTED_TEMPLATE_TAB = "SET_SELECTED_TEMPLATE_TAB";
export const FETCH_PRODUCT_DL = "FETCH_PRODUCT_DL";
export const FETCH_PRODUCT_DL_SUCCESS = "FETCH_PRODUCT_DL_SUCCESS";
export const FETCH_PRODUCT_DL_FAILED = "FETCH_PRODUCT_DL_FAILED";
export const CANCEL_FETCH_PRODUCT_DL = "CANCEL_FETCH_PRODUCT_DL";
export const UPDATE_PRODUCT_DL = "UPDATE_PRODUCT_DL";
export const UPDATE_PRODUCT_DL_SUCCESS = "UPDATE_PRODUCT_DL_SUCCESS";
export const UPDATE_PRODUCT_DL_FAILED = "UPDATE_PRODUCT_DL_FAILED";
export const FETCH_DATA_SOURCE_COLUMNS = "FETCH_DATA_SOURCE_COLUMNS";
export const FETCH_DATA_SOURCE_COLUMNS_SUCCESS =
  "FETCH_DATA_SOURCE_COLUMNS_SUCCESS";
export const FETCH_DATA_SOURCE_COLUMNS_FAILED =
  "FETCH_DATA_SOURCE_COLUMNS_FAILED";
