import { put, takeLatest, call } from "redux-saga/effects";
import { GetScheduler, GetSchedulerInfo } from "../../api/productApi";
import {
  FETCH_SCHEDULES,
  FETCH_SCHEDULE_INFO,
  FETCH_SCHEDULE_INFO_FAILED,
  FETCH_SCHEDULE_INFO_SUCCESS
} from "../types/schedules";
import {
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILED
} from "../types/schedules";

function* getSchedules(action) {
  try {
    const schedules = yield call(async () => {
      return await GetScheduler(action.product.id);
    });
    yield put({ type: FETCH_SCHEDULES_SUCCESS, schedules: schedules.data });
  } catch (e) {
    yield put({ type: FETCH_SCHEDULES_FAILED, message: e });
  }
}

function* getSchedulesInfo(action) {
  try {
    const scheduleInfo = yield call(async () => {
      return await GetSchedulerInfo(action.productId);
    });
    yield put({
      type: FETCH_SCHEDULE_INFO_SUCCESS,
      scheduleInfo: scheduleInfo.data,
      productId: action.productId
    });
  } catch (e) {
    yield put({ type: FETCH_SCHEDULE_INFO_FAILED, message: e });
    // TODO fix schedulerby John
    // yield put({
    //   type: OPEN_SNACKBAR,
    //   snackbarType: "error",
    //   message: "Error getting schedule info"
    // });
  }
}

function* schedule() {
  yield takeLatest(FETCH_SCHEDULES, getSchedules);
  yield takeLatest(FETCH_SCHEDULE_INFO, getSchedulesInfo);
}

export default schedule;
