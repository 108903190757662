export const FETCH_WORKSPACES = "FETCH_WORKSPACES";
export const FETCH_WORKSPACES_SUCCESS = "FETCH_WORKSPACES_SUCCESS";
export const FETCH_WORKSPACES_FAILED = "FETCH_WORKSPACES_FAILED";
export const SELECT_WORKSPACE = "SELECT_WORKSPACE";
export const FETCH_SEARCH_WORKSPACE_COMPONENTS =
  "FETCH_SEARCH_WORKSPACE_COMPONENTS";
export const FETCH_SEARCH_WORKSPACE_COMPONENTS_SUCCESS =
  "FETCH_SEARCH_WORKSPACE_COMPONENTS_SUCCESS";
export const FETCH_SEARCH_WORKSPACE_COMPONENTS_FAILED =
  "FETCH_SEARCH_WORKSPACE_COMPONENTS_FAILED";
export const SET_SEARCH_PRODUCTS = "SET_SEARCH_PRODUCTS";
export const SET_IS_SEARCH_PRODUCTS = "SET_IS_SEARCH_PRODUCTS";
