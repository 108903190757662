Array.prototype.remByVal = function(property, val) {
  let removedValue;
  for (var i = 0; i < this.length; i++) {
    if (this[i][property] === val) {
      removedValue = this.splice(i, 1);
      i--;
    }
  }
  return { arr: this, removedValue };
};

Array.prototype.getByVal = function(property, val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i][property] === val) {
      return this[i];
    }
  }
  return undefined;
};

Array.prototype.convertArrayToObject = function(key, value) {
  const initialValue = {};
  return this.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value]
    };
  }, initialValue);
};

// Warn if overriding existing method
if (Array.prototype.equals)
  console.warn(
    "Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
  );
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function(array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", { enumerable: false });

export const compare = (a, b, field, asc = false) => {
  if (a && b && a[field] && b[field] && a[field] !== 0 && b[field] !== 0) {
    if (asc) {
      if (Number(a[field]) < Number(b[field])) {
        return -1;
      }
      if (Number(a[field]) > Number(b[field])) {
        return 1;
      }
      return 0;
    } else {
      if (Number(a[field]) < Number(b[field])) {
        return 1;
      }
      if (Number(a[field]) > Number(b[field])) {
        return -1;
      }
      return 0;
    }
  }
  return 0;
};

export const isAnyNotNull = (values, property) => {
  for (const v of values) {
    if (v[property]) {
      return true;
    }
  }
  return false;
};

export const getAllBefore = (current, arr) => {
  let myArr = arr;
  return current > 0 ? myArr.slice(0, current) : [];
};

export const groupBy = (collection, property) => {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  for (; i < collection.length; i++) {
    if (!collection[i]) continue;
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
};
