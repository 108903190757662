export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";
export const FETCH_PRODUCTS_INITIALS_SUCCESS =
  "FETCH_PRODUCTS_INITIALS_SUCCESS";
export const FETCH_PRODUCTS_INITIALS_FAILED = "FETCH_PRODUCTS_INITIALS_FAILED";
export const FETCH_PRODUCTS_INITIALS = "FETCH_PRODUCTS_INITIALS";
export const CREATE_HISTORY = "CREATE_HISTORY";
export const CREATE_HISTORY_SUCCEEDED = "CREATE_HISTORY_SUCCEEDED";
export const CREATE_HISTORY_FAILED = "CREATE_HISTORY_FAILED";
export const UPDATE_PRODUCT_TEMPLATE = "UPDATE_PRODUCT_TEMPLATE";
export const UPDATE_PRODUCT_TEMPLATE_SUCCEEDED =
  "UPDATE_PRODUCT_TEMPLATE_SUCCEEDED";
export const UPDATE_PRODUCT_TEMPLATE_FAILED = "UPDATE_PRODUCT_TEMPLATE_FAILED";
export const SET_SEARCH_PRODUCTS = "SET_SEARCH_PRODUCTS";
export const SET_IS_SEARCH_PRODUCTS = "SET_IS_SEARCH_PRODUCTS";
export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS";
export const SET_DUMMY_DATA = "SET_DUMMY_DATA";
