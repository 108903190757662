export const FETCH_MANAGED_USERS_TABLES = "FETCH_MANAGED_USERS_TABLES";
export const FETCH_MANAGED_USERS_TABLES_SUCCEEDED =
  "FETCH_MANAGED_USERS_TABLES_SUCCEEDED";
export const FETCH_MANAGED_USERS_TABLES_FAILED =
  "FETCH_MANAGED_USERS_TABLES_FAILED";
export const FETCH_MANAGED_USERS_TABLES_FILTER =
  "FETCH_MANAGED_USERS_TABLES_FILTER";
export const FETCH_MANAGED_USERS_TABLES_FILTER_SUCCEEDED =
  "FETCH_MANAGED_USERS_TABLES_FILTER_SUCCEEDED";
export const FETCH_MANAGED_USERS_TABLES_FILTER_FAILED =
  "FETCH_MANAGED_USERS_TABLES_FILTER_FAILED";
export const SET_MANAGED_USER_TABLE = "SET_MANAGED_USER_TABLE";
export const FETCH_MANAGED_USER_TABLE_COLUMN_KEYS =
  "FETCH_MANAGED_USER_TABLE_COLUMN_KEYS";
export const FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_SUCCESS =
  "FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_SUCCESS";
export const FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_FAILED =
  "FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_FAILED";
export const ADD_MANAGED_USER_TABLE_FILTER = "ADD_MANAGED_USER_TABLE_FILTER";
export const ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED =
  "ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED";
export const ADD_MANAGED_USER_TABLE_FILTER_FAILED =
  "ADD_MANAGED_USER_TABLE_FILTER_FAILED";
export const GET_MANAGED_USER_TABLE_DATA = "GET_MANAGED_USER_TABLE_DATA";
export const GET_MANAGED_USER_TABLE_DATA_SUCCEEDED =
  "GET_MANAGED_USER_TABLE_DATA_SUCCEEDED";
export const GET_MANAGED_USER_TABLE_DATA_FAILED =
  "GET_MANAGED_USER_TABLE_DATA_FAILED";
export const SET_COLUMN_FILTER_VALUE = "SET_COLUMN_FILTER_VALUE";
export const ADD_MANAGED_TABLE_USER_SEARCH_FILTER =
  "ADD_MANAGED_TABLE_USER_SEARCH_FILTER";
export const CLEAR_MANAGED_TABLE_USER_SEARCH_FILTER =
  "CLEAR_MANAGED_TABLE_USER_SEARCH_FILTER";
export const CHANGE_SEARCH_COLUMN_FILTER = "CHANGE_SEARCH_COLUMN_FILTER";
export const CLEAR_SEARCH_COLUMN_FILTER = "CLEAR_SEARCH_COLUMN_FILTER";
export const UPDATE_MANAGED_USER_TABLE_FILTER =
  "UPDATE_MANAGED_USER_TABLE_FILTER";
export const UPDATE_MANAGED_USER_TABLE_FILTER_SUCCEEDED =
  "UPDATE_MANAGED_USER_TABLE_FILTER_SUCCEEDED";
export const UPDATE_MANAGED_USER_TABLE_FILTER_FAILED =
  "UPDATE_MANAGED_USER_TABLE_FILTER_FAILED";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const DELETE_MANAGED_USER_TABLE_FILTER =
  "DELETE_MANAGED_USER_TABLE_FILTER";
export const DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED =
  "DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED";
export const DELETE_MANAGED_USER_TABLE_FILTER_FAILED =
  "DELETE_MANAGED_USER_TABLE_FILTER_FAILED";
