import { fork } from "redux-saga/effects";
import iconSaga from "./icon";
import scheduleSaga from "./schedule";
import productSaga from "./product";
import channelSaga from "./channel";
import snippetSaga from "./snippet";
import dbTableSaga from "./dbTable";
import dataSourceSaga from "./dataSource";
import dlSaga from "./dl";
import appSaga from "./apps";
import ctlRulesSaga from "./ctlRules";
import workspacesSaga from "./workspaces";
import managedUserSaga from "./managedUser";
import templateSnippetSaga from "./templateSnippet";
import productTemplateSaga from "./productTemplate";
import productDataSaga from "./productData";
import ninjaDlSaga from "./ninjaDl";
import tenant from "./tenant";
import realTimeData from "./realTimeData";

function* mySaga() {
  yield fork(productSaga);
  yield fork(snippetSaga);
  yield fork(dbTableSaga);
  yield fork(dataSourceSaga);
  yield fork(channelSaga);
  yield fork(scheduleSaga);
  yield fork(iconSaga);
  yield fork(dlSaga);
  yield fork(appSaga);
  yield fork(ctlRulesSaga);
  yield fork(workspacesSaga);
  yield fork(managedUserSaga);
  yield fork(templateSnippetSaga);
  yield fork(productTemplateSaga);
  yield fork(productDataSaga);
  yield fork(ninjaDlSaga);
  yield fork(tenant);
  yield fork(realTimeData);
}

export default mySaga;
