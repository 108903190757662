export const FETCH_REAL_TIME_KPI_DATA = "FETCH_REAL_TIME_KPI_DATA";
export const FETCH_REAL_TIME_KPI_DATA_SUCCESS =
  "FETCH_REAL_TIME_KPI_DATA_SUCCESS";
export const FETCH_REAL_TIME_KPI_DATA_FAILED =
  "FETCH_REAL_TIME_KPI_DATA_FAILED";
export const FETCH_PRODUCT_USERS = "FETCH_PRODUCT_USERS";
export const FETCH_PRODUCT_USERS_SUCCESS = "FETCH_PRODUCT_USERS_SUCCESS";
export const FETCH_PRODUCT_USERS_FAILED = "FETCH_PRODUCT_USERS_FAILED";
export const SET_PRODUCT_USER = "SET_PRODUCT_USER";
export const FETCH_PRODUCT_CALCULATION = "FETCH_PRODUCT_CALCULATION";
export const FETCH_PRODUCT_CALCULATION_SUCCESS =
  "FETCH_PRODUCT_CALCULATION_SUCCESS";
export const FETCH_PRODUCT_CALCULATION_FAILED =
  "FETCH_PRODUCT_CALCULATION_FAILED";
export const CREATE_MAT_VIEW = "CREATE_MAT_VIEW";
export const CREATE_MAT_VIEW_SUCCESS = "CREATE_MAT_VIEW_SUCCESS";
export const CREATE_MAT_VIEW_FAILED = "CREATE_MAT_VIEW_FAILED";
export const UPDATE_DL_PROCEDURE = "UPDATE_DL_PROCEDURE";
export const UPDATE_DL_PROCEDURE_SUCCESS = "UPDATE_DL_PROCEDURE_SUCCESS";
export const UPDATE_DL_PROCEDURE_FAILED = "UPDATE_DL_PROCEDURE_FAILED";
export const UPDATE_DATA_SOURCE_PROCEDURE = "UPDATE_DATA_SOURCE_PROCEDURE";
export const UPDATE_DATA_SOURCE_PROCEDURE_SUCCESS =
  "UPDATE_DATA_SOURCE_PROCEDURE_SUCCESS";
export const UPDATE_DATA_SOURCE_PROCEDURE_FAILED =
  "UPDATE_DATA_SOURCE_PROCEDURE_FAILED";
