import { put, call, takeLatest, delay, select } from "redux-saga/effects";
import { GetProductTemplateSnippets } from "../../api/productTemplate";
import {
  DeleteSnippet,
  SaveSnippet,
  SaveTemplateSnippets
} from "../../api/snippetApi";
import { isEligableToRun } from "../../util/realTime";
import { SET_PRODUCT } from "../types/products";
import { FETCH_PRODUCT_CALCULATION } from "../types/realTimeData";
import { OPEN_SNACKBAR } from "../types/snackbar";
import {
  FETCH_TEMPLATE_SNIPPETS,
  FETCH_TEMPLATE_SNIPPETS_SUCCESS,
  FETCH_TEMPLATE_SNIPPETS_FAILED,
  UPDATE_TEMPLATE_SNIPPET,
  UPDATE_TEMPLATE_SNIPPET_SUCCESS,
  UPDATE_TEMPLATE_SNIPPET_FAILED,
  ADD_TEMPLATE_SNIPPET,
  DELETE_TEMPLATE_SNIPPET,
  ADD_TEMPLATE_SNIPPET_SUCCESS,
  ADD_TEMPLATE_SNIPPET_FAILED,
  REORDER_TEMPLATE_SNIPPET_SUCCESS,
  REORDER_TEMPLATE_SNIPPET,
  DELETE_TEMPLATE_SNIPPET_SUCCESS,
  DELETE_TEMPLATE_SNIPPET_FAILED,
  DUPLICATE_TEMPLATE_SNIPPET_SUCCESS,
  DUPLICATE_TEMPLATE_SNIPPET_FAILED,
  DUPLICATE_TEMPLATE_SNIPPET
} from "../types/templateSnippet";

function* getTemplateSnippts(action) {
  try {
    const templateSnippets = yield call(async () => {
      return await GetProductTemplateSnippets(
        action.templateId,
        action.channel
      );
    });
    const state = yield select();
    const product = state.products.product;
    yield put({
      type: FETCH_TEMPLATE_SNIPPETS_SUCCESS,
      templateSnippets: templateSnippets.data.snippets
    });
    yield put({
      type: SET_PRODUCT,
      product: { ...product, workspaceId: templateSnippets.data.workspaceId }
    });
  } catch (e) {
    yield put({ type: FETCH_TEMPLATE_SNIPPETS_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error getting template snippets"
    });
  }
}

function* updateTemplateSnippet(action) {
  try {
    const state = yield select();
    let snippets = state.templateSnippet.templateSnippets;
    const snippet = snippets[action.index];
    snippet[action.property] = action.value;
    snippets[action.index] = snippet;
    yield put({
      type: UPDATE_TEMPLATE_SNIPPET_SUCCESS,
      templateSnippets: snippets
    });
    yield delay(200);
    if (action.saveBackend) {
      const templateSnippets = yield call(async () => {
        return await SaveSnippet({
          savedSnippet: {
            ...snippet,
            template: action.templateId,
            templateSnippet: {
              ...snippet.templateSnippet,
              componentOrder: action.index,
              templateSnippetChannel: {
                templateSnippetId: snippet.templateSnippet.id,
                componentOrder: action.index,
                channelId: state.activeChannel.channel
              },
              backDays: snippet.templateSnippet?.backDays || null
            }
          },
          allSnippets: snippets,
          channel: state.activeChannel.channel
        });
      });
      if (!snippet.id) {
        snippets[action.index] = { ...snippet, ...templateSnippets.data };
        yield put({
          type: UPDATE_TEMPLATE_SNIPPET_SUCCESS,
          templateSnippets: snippets
        });
      }
    }
    const { result } = isEligableToRun(snippet);
    if (result) {
      let snippets = state.templateSnippet.templateSnippets;
      const snippet = snippets[action.index];
      snippets[action.index] = {
        ...snippet,
        updated: (snippet.updated || 0) + 1
      };
      yield put({
        type: UPDATE_TEMPLATE_SNIPPET_SUCCESS,
        templateSnippets: snippets
      });
    }
  } catch (e) {
    yield put({ type: UPDATE_TEMPLATE_SNIPPET_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error updating snippet"
    });
  }
}

function* addTemplateSnippet(action) {
  try {
    const state = yield select();
    const tempSnippets = state.templateSnippet.templateSnippets;
    let newSnippet = {
      type: "box",
      id: undefined,
      snippet: action.item.id,
      template: action.templateId,
      templateSnippet: {
        template: {
          id: action.templateId
        },
        componentOrder: action.destination.index,
        templateSnippetChannel: {
          templateSnippetId: undefined,
          componentOrder: action.destination.index,
          channelId: state.activeChannel.channel
        }
      },
      snippetTypeName:
        action.item && action.item.snippetType
          ? action.item.snippetType.name
          : undefined
    };
    if (action.item.initialData)
      newSnippet = { ...newSnippet, ...action.item.initialData };
    const templateSnippets = yield call(async () => {
      return await SaveSnippet({
        savedSnippet: {
          ...newSnippet,
          template: action.templateId
        },
        allSnippets: tempSnippets,
        channel: state.activeChannel.channel
      });
    });
    newSnippet = { ...newSnippet, ...templateSnippets.data };
    tempSnippets.splice(action.destination.index, 0, {
      ...newSnippet
    });

    yield put({
      type: ADD_TEMPLATE_SNIPPET_SUCCESS,
      templateSnippets: tempSnippets,
      destinationIndex: action.destination?.index,
      templateId: action.templateId,
      addDivider:
        action.item &&
        action.item.snippetType &&
        action.item.snippetType.name != "divider" &&
        ![2].includes(state.activeChannel?.channel)
    });
  } catch (e) {
    yield put({ type: ADD_TEMPLATE_SNIPPET_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error adding snippet"
    });
  }
}
function* addDivider(action) {
  const {
    addDivider,
    templateSnippets: tempSnippets,
    destinationIndex
  } = action;

  if (!addDivider) {
    return;
  }

  const dividerSnippet = {
    id: 19,
    name: "Divider",
    snippetType: {
      id: 14,
      name: "divider"
    },
    thumbnail:
      "http://cdn.mcauto-images-production.sendgrid.net/918ffbc2e8159ad5/5f8a8589-5a6b-466b-b6b0-ea71c7434a61/100x89.png",
    description: "Divider"
  };

  try {
    const state = yield select();

    let newSnippet = {
      type: "box",
      id: undefined,
      snippet: dividerSnippet.id,
      template: action.templateId,
      templateSnippet: {
        template: {
          id: action.templateId
        },
        componentOrder: destinationIndex + 1,
        templateSnippetChannel: {
          templateSnippetId: undefined,
          componentOrder: destinationIndex + 1,
          channelId: state.activeChannel.channel
        }
      },
      snippetTypeName: "divider"
    };

    const templateSnippets = yield call(async () => {
      return await SaveSnippet({
        savedSnippet: {
          ...newSnippet,
          template: action.templateId
        },
        allSnippets: tempSnippets,
        channel: state.activeChannel.channel
      });
    });
    newSnippet = { ...newSnippet, ...templateSnippets.data };
    tempSnippets.splice(destinationIndex + 1, 0, {
      ...newSnippet
    });
    yield put({
      type: ADD_TEMPLATE_SNIPPET_SUCCESS,
      templateSnippets: tempSnippets
    });
  } catch (e) {
    yield put({ type: ADD_TEMPLATE_SNIPPET_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error adding snippet"
    });
  }
}
function* reorderTemplateSnippets(action) {
  try {
    const state = yield select();
    let templateSnippets = state.templateSnippet.templateSnippets;
    var tmp = templateSnippets[action.source.index];
    templateSnippets[action.source.index] =
      templateSnippets[action.destination.index];
    templateSnippets[action.destination.index] = tmp;
    yield put({
      type: REORDER_TEMPLATE_SNIPPET_SUCCESS,
      templateSnippets: templateSnippets
    });
    yield call(async () => {
      return await SaveTemplateSnippets(
        templateSnippets,
        state.activeChannel.channel
      );
    });
  } catch (e) {
    yield put({ type: ADD_TEMPLATE_SNIPPET_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error reordering snippet"
    });
  }
}

function* deleteTemplateSnippet(action) {
  try {
    const state = yield select();
    let snippets = state.templateSnippet.templateSnippets;
    snippets.splice(action.index, 1);
    yield put({
      type: DELETE_TEMPLATE_SNIPPET_SUCCESS,
      templateSnippets: snippets
    });
    if (action.snippet.templateSnippet && action.snippet.templateSnippet.id) {
      yield call(async () => {
        return await DeleteSnippet(
          action.snippet.templateSnippet.id,
          state.activeChannel.channel
        );
      });
    }
  } catch (e) {
    yield put({ type: DELETE_TEMPLATE_SNIPPET_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error deleting snippet"
    });
  }
}

function* duplicateTemplateSnippet(action) {
  try {
    const state = yield select();
    let snippet = {
      ...action.templateSnippet,
      id: undefined,
      name: undefined,
      title: undefined,
      type: "box",
      snippet: action.templateSnippet.snippet,
      template: action.templateId,
      templateSnippet: {
        template: {
          id: action.templateId
        },
        componentOrder: action.index + 1,
        templateSnippetChannel: {
          templateSnippetId: undefined,
          componentOrder: action.index + 1,
          channelId: state.activeChannel.channel
        },
        dataBlending: action.templateSnippet?.templateSnippet?.dataBlending
      },
      snippetTypeName: action.templateSnippet.snippetTypeName
    };
    const response = yield call(async () => {
      return await SaveSnippet({
        savedSnippet: {
          ...snippet,
          template: action.templateId
        },
        allSnippets: state.templateSnippet.templateSnippets
      });
    });
    let tempSnippets = state.templateSnippet.templateSnippets;
    tempSnippets.splice(action.index + 1, 0, {
      ...response.data,
      snippetTypeName: action.templateSnippet.snippetTypeName
    });
    yield call(async () => {
      return await SaveTemplateSnippets(
        tempSnippets,
        state.activeChannel.channel
      );
    });
    yield put({
      type: DUPLICATE_TEMPLATE_SNIPPET_SUCCESS,
      templateSnippets: tempSnippets
    });
    const { result } = isEligableToRun({
      ...response.data,
      snippetTypeName: action.templateSnippet.snippetTypeName
    });
    if (result) {
      yield put({ type: FETCH_PRODUCT_CALCULATION });
    }
  } catch (e) {
    yield put({ type: DUPLICATE_TEMPLATE_SNIPPET_FAILED, message: e });
    yield put({
      type: OPEN_SNACKBAR,
      snackbarType: "error",
      message: "Error duplicating snippet"
    });
  }
}

function* templateSnippet() {
  yield takeLatest(FETCH_TEMPLATE_SNIPPETS, getTemplateSnippts);
  yield takeLatest(UPDATE_TEMPLATE_SNIPPET, updateTemplateSnippet);
  yield takeLatest(ADD_TEMPLATE_SNIPPET, addTemplateSnippet);
  yield takeLatest(DELETE_TEMPLATE_SNIPPET, deleteTemplateSnippet);
  yield takeLatest(REORDER_TEMPLATE_SNIPPET, reorderTemplateSnippets);
  yield takeLatest(DUPLICATE_TEMPLATE_SNIPPET, duplicateTemplateSnippet);
  yield takeLatest(ADD_TEMPLATE_SNIPPET_SUCCESS, addDivider);
}

export default templateSnippet;
