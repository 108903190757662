export const FETCH_DATA_SOURCES = "FETCH_DATA_SOURCES";
export const FETCH_DATA_SOURCES_SUCCESS = "FETCH_DATA_SOURCES_SUCCESS";
export const FETCH_DATA_SOURCES_FAILED = "FETCH_DATA_SOURCES_FAILED";
export const SET_TEMPLATE_DATA_SOURCES = "SET_TEMPLATE_DATA_SOURCES";
export const FETCH_TEMPLATE_DATA_SOURCES = "FETCH_TEMPLATE_DATA_SOURCES";
export const FETCH_TEMPLATE_DATA_SOURCES_SUCCESS =
  "FETCH_TEMPLATE_DATA_SOURCES_SUCCESS";
export const FETCH_TEMPLATE_DATA_SOURCES_FAILED =
  "FETCH_TEMPLATE_DATA_SOURCES_FAILED";
export const FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS =
  "FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS";
export const FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_SUCCESS =
  "FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_SUCCESS";
export const FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_FAILED =
  "FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_FAILED";
export const SET_SELECTED_DATASOURCE_TYPE = "SET_SELECTED_DATASOURCE_TYPE";
export const FETCH_FORMS = "FETCH_FORMS";
export const FETCH_FORMS_SUCCESS = "FETCH_FORMS_SUCCESS";
export const FETCH_FORMS_FAILED = "FETCH_FORMS_FAILED";
export const FETCH_SEARCH_DATA_SOURCES = "FETCH_SEARCH_DATA_SOURCES";
export const FETCH_SEARCH_DATA_SOURCES_SUCCESS =
  "FETCH_SEARCH_DATA_SOURCES_SUCCESS";
export const FETCH_SEARCH_DATA_SOURCES_FAILED =
  "FETCH_SEARCH_DATA_SOURCES_FAILED";
