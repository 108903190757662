import { put, call, takeLatest } from "redux-saga/effects";
import { GetSnippets } from "../../api/snippetApi";
import {
  FETCH_SNIPPETS,
  FETCH_SNIPPETS_FAILED,
  FETCH_SNIPPETS_SUCCESS
} from "../types/snippets";

function* getSnippts(action) {
  try {
    const snippets = yield call(async () => {
      return await GetSnippets(action.channel);
    });
    yield put({ type: FETCH_SNIPPETS_SUCCESS, snippets: snippets.data });
  } catch (e) {
    yield put({ type: FETCH_SNIPPETS_FAILED, message: e });
  }
}

function* snippet() {
  yield takeLatest(FETCH_SNIPPETS, getSnippts);
}

export default snippet;
