export const SET_DL_COLUMNS = "SET_DL_COLUMNS";
export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCEEDED = "FETCH_ALL_USERS_SUCCEEDED";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCEEDED = "FETCH_USERS_SUCCEEDED";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCEEDED = "ADD_USER_SUCCEEDED";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const FETCH_ASSETS = "FETCH_ASSETS";
export const FETCH_ASSETS_SUCCEEDED = "FETCH_ASSETS_SUCCEEDED";
export const FETCH_ASSETS_FAILED = "FETCH_ASSETS_FAILED";
export const ADD_ASSET = "ADD_ASSET";
export const ADD_ASSET_SUCCEEDED = "ADD_ASSET_SUCCEEDED";
export const ADD_ASSET_FAILED = "ADD_ASSET_FAILED";
export const UPDATE_ASSETS = "UPDATE_ASSETS";
export const UPDATE_ASSETS_SUCCEEDED = "UPDATE_ASSETS_SUCCEEDED";
export const UPDATE_ASSETS_FAILED = "UPDATE_ASSETS_FAILED";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_SUCCEEDED = "UPDATE_USERS_SUCCEEDED";
export const UPDATE_USERS_FAILED = "UPDATE_USERS_FAILED";
export const SET_FILTERED_USERS = "SET_FILTERED_USERS";
export const SET_FILTERED_ASSETS = "SET_FILTERED_ASSETS";
export const SET_FILTERED_ALL_USERS = "SET_FILTERED_ALL_USERS";
export const UPDATE_ALL_USERS = "UPDATE_ALL_USERS";
export const UPDATE_ALL_USERS_FAILED = "UPDATE_ALL_USERS_FAILED";
export const UPDATE_ALL_USERS_SUCCEEDED = "UPDATE_ALL_USERS_SUCCEEDED";
export const FETCH_ALL_ASSETS = "FETCH_ALL_ASSETS";
export const FETCH_ALL_ASSETS_SUCCEEDED = "FETCH_ALL_ASSETS_SUCCEEDED";
export const FETCH_ALL_ASSETS_FAILED = "FETCH_ALL_ASSETS_FAILED";
