export const FETCH_DLS = "FETCH_DLS";
export const FETCH_DLS_SUCCEEDED = "FETCH_DLS_SUCCEEDED";
export const FETCH_DLS_FAILED = "FETCH_DLS_FAILED";
export const SET_SELECTED_DL = "SET_SELECTED_DL";
export const UPDATE_DL = "UPDATE_DL";
export const REMOVE_DL = "REMOVE_DL";
export const SET_SAVING = "SET_SAVING";
export const SET_UPLOAD_TASKID = "SET_UPLOAD_TASKID";
export const FETCH_COLUMN_VALUES = "FETCH_COLUMN_VALUES";
export const FETCHED_COLUMN_VALUES = "FETCHED_COLUMN_VALUES";
export const CANCEL_FETCH_COLUMN_VALUES = "CANCEL_FETCH_COLUMN_VALUES";
