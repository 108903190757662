import { createMuiTheme } from "@material-ui/core/styles";
import {
  HpPrimary,
  PurplePrimary,
  White,
  DarkBlue,
  PrimaryGray,
  ActionBlue,
  switchGreyColor,
  DisabledTextGrey
} from "../src/const/colors.js";

export const themeLight = createMuiTheme({
  palette: {
    primary: { main: PurplePrimary },
    text: { primary: "#2B2A35" },
    textColor: HpPrimary,
    background: {
      default: "#fff"
    },
    label: {
      textColor: HpPrimary
    }
  },
  typography: {
    fontFamily: "'Nunito Sans', sans-serif",
    button: {
      textTransform: "none"
    }
  },
  MuiButton: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  body: {
    margin: "0px"
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    body: {
      margin: "0px"
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#7070FA"
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {}
    },
    MuiPickersClock: {
      pin: { backgroundColor: "#7070FA" }
    },
    MuiPickersClockPointer: {
      pointer: { backgroundColor: "#7070FA" },
      thumb: { backgroundColor: "#7070FA", borderColor: "#7070FA !important" }
    },

    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#7070FA",
        color: "white",
        "&:hover": {
          backgroundColor: "#7070FA",
          color: "white"
        }
      },
      current: {
        color: DarkBlue
      }
    },
    MuiSwitch: {
      root: {
        height: "34px",
        width: "54px"
      },
      thumb: {
        color: "white",
        width: "16px",
        height: "16px"
      },
      switchBase: {
        color: switchGreyColor,
        "&$checked": {
          transform: "translateX(19px)",
          color: White,
          boxShadow: "none"
        },
        "&$checked + $track": {
          backgroundColor: ActionBlue,
          opacity: 1
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: PrimaryGray,
        "&$focused": {
          color: PrimaryGray
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        fontSize: "14px",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "rgba(84, 90, 105, 0.2)"
        },
        "&$disabled": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: DisabledTextGrey,
            color: DisabledTextGrey,
            opacity: "0.5"
          }
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "rgba(84, 90, 105, 0.2)",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(84, 90, 105, 0.2)"
          }
        },
        "&$focused $notchedOutline": {
          borderColor: "rgba(84, 90, 105, 0.2)",
          borderWidth: 1
        }
      },
      input: {
        padding: "10px"
      },
      "&:hover": {
        borderColor: "transparent"
      }
    },
    MuiInput: {
      root: {
        fontSize: "12px",
        padding: "5px 20px"
      },
      "&:hover": {
        borderColor: "transparent"
      },
      underline: {
        "&&:after": {
          borderColor: "transparent"
        },
        "&:hover:not($disabled):after": {
          borderColor: "transparent"
        },
        "&:hover:not($disabled):before": {
          borderColor: "transparent"
        }
      }
    },
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color"
        }
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#fff",
        color: "#2B2A35",
        height: "60px"
      }
    },
    MuiFormLabel: {
      color: HpPrimary
    },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px"
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(255, 255, 255, 1)",
          borderLeft: "1px solid #EBEEF7"
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: switchGreyColor,
          borderRadius: "40px"
        },
        "input::-webkit-autofill": {
          "-webkit-text-fill-color": "yellow !important"
        },
        "input::-internal-autofill-selected": {
          background: "red !important"
        },
        "input:-webkit-autofill,input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
          "-webkit-box-shadow": "0 0 0 30px white inset !important"
        },
        "::selection": {
          backgroundColor: "rgba(103,180,253,0.8)"
        }
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: "8px"
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: "#7070FA !important"
      }
    },
    MuiTooltip: {
      popper: { zIndex: "999999" }
    }
  }
});
