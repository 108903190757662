export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const jsonToBase64 = obj => {
  if (!obj || obj.length === 0) {
    return null;
  }
  let objJsonStr = JSON.stringify(obj);
  return Buffer.from(objJsonStr).toString("base64");
};

export const base64ToJson = value => {
  if (!value) {
    return null;
  }
  return JSON.parse(atob(value));
};
