import { put, call, takeLatest } from "redux-saga/effects";
import { AutoAuthenticateUser } from "../../api/authApi";
import {
  SET_TENANT,
  SET_TENANT_FAILED,
  SET_TENANT_SUCCESS
} from "../types/tenant";

function* getTenantToken(action) {
  try {
    if (action.runBackend) {
      const response = yield call(async () => {
        return await AutoAuthenticateUser(action.tenant.tenant_id);
      });
      localStorage.token = response.data.content.token;
    }
    yield put({ ...action, type: SET_TENANT_SUCCESS });
  } catch (e) {
    yield put({ type: SET_TENANT_FAILED, message: e });
  }
}

function* tenant() {
  yield takeLatest(SET_TENANT, getTenantToken);
}

export default tenant;
