export const FETCH_TEMPLATE_SNIPPETS = "FETCH_TEMPLATE_SNIPPETS";
export const FETCH_TEMPLATE_SNIPPETS_SUCCESS =
  "FETCH_TEMPLATE_SNIPPETS_SUCCESS";
export const FETCH_TEMPLATE_SNIPPETS_FAILED = "FETCH_TEMPLATE_SNIPPETS_FAILED";
export const UPDATE_TEMPLATE_SNIPPET = "UPDATE_TEMPLATE_SNIPPET";
export const UPDATE_TEMPLATE_SNIPPET_SUCCESS =
  "UPDATE_TEMPLATE_SNIPPET_SUCCESS";
export const UPDATE_TEMPLATE_SNIPPET_FAILED = "UPDATE_TEMPLATE_SNIPPET_FAILED";
export const ADD_TEMPLATE_SNIPPET = "ADD_TEMPLATE_SNIPPET";
export const ADD_TEMPLATE_SNIPPET_SUCCESS = "ADD_TEMPLATE_SNIPPET_SUCCESS";
export const ADD_TEMPLATE_SNIPPET_FAILED = "ADD_TEMPLATE_SNIPPET_FAILED";
export const DELETE_TEMPLATE_SNIPPET = "DELETE_TEMPLATE_SNIPPET";
export const DELETE_TEMPLATE_SNIPPET_SUCCESS =
  "DELETE_TEMPLATE_SNIPPET_SUCCESS";
export const DELETE_TEMPLATE_SNIPPET_FAILED = "DELETE_TEMPLATE_SNIPPET_FAILED";
export const REORDER_TEMPLATE_SNIPPET = "REORDER_TEMPLATE_SNIPPET";
export const REORDER_TEMPLATE_SNIPPET_SUCCESS =
  "REORDER_TEMPLATE_SNIPPET_SUCCESS";
export const REORDER_TEMPLATE_SNIPPET_FAILED =
  "REORDER_TEMPLATE_SNIPPET_FAILED";
export const DUPLICATE_TEMPLATE_SNIPPET = "DUPLICATE_TEMPLATE_SNIPPET";
export const DUPLICATE_TEMPLATE_SNIPPET_SUCCESS =
  "DUPLICATE_TEMPLATE_SNIPPET_SUCCESS";
export const DUPLICATE_TEMPLATE_SNIPPET_FAILED =
  "DUPLICATE_TEMPLATE_SNIPPET_FAILED";
export const SET_TEMPLATE_SNIPPETS = "SET_TEMPLATE_SNIPPETS";
